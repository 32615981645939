var isSP = function() {
	return $('.sp-view:visible').length != 0;
};

// Retina対応
document.createElement('picture');

// scroll
$(function(){
	$('a[href^="#"]' + 'a:not(.non-scroll)').on('click', function(){
    var speed = 800;
    var href= $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    var position = target.offset().top;
    $('html, body').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
});

// SP header menu
$(function() {
	let posi;
	$('.header-menu').on('click', function() {
		posi = $(window).scrollTop();
		$('nav.sp-menu').scrollTop(0)
		$('div.wrap').css({
			top: -1 * posi
		});
		$(this).toggleClass('active');
		$('body').toggleClass('show');
	});
	$('.menu-close').on('click', function() {
		$('div.wrap').attr('style', '');
		$('html, body').prop({scrollTop: posi});
		$('.header-menu').removeClass('active');
		$('body').removeClass('show');
	});
});
// accordion
$(function() {
	$('.accordion').click(function() {
		$(this).next().slideToggle();
		$(this).toggleClass('active');

	});
	$('.gnav__item__link--accordion').click(function() {
		$(this).next().slideToggle();
		// $(this).slideToggle(1000, ()=>{alert('hello!')});
		$(this).toggleClass('active');
	});
	$('.fnav__title--accordion').click(function() {
		$(this).next().slideToggle();
		// $(this).slideToggle(1000, ()=>{alert('hello!')});
		$(this).toggleClass('active');
	});
	$('.sp-accordion').click(function() {
		if (isSP()) {
			$(this).next().slideToggle();
			$(this).toggleClass('active');
		}
	});
});


// pagetop
$(function() {
	var topBtn = $('.pagetop');
	topBtn.hide();
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			topBtn.fadeIn();
		} else {
			topBtn.fadeOut();
		}
	});
    topBtn.click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
		return false;
    });
});

// tel link
var ua = navigator.userAgent.toLowerCase();
var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);

if (!isMobile) {
    $('a[href^="tel:"]').on('click', function(e) {
        e.preventDefault();
    });
}