(function( $ ){
	$.fn.footerMenu = function() {
		$(window).scroll(function() {
	    	if ($(document).scrollTop() > 100) {
	      		$('.footer-menu').addClass("show");
	    	} else {
	      		$('.footer-menu').removeClass("show");
	    	}
	  	});
	};
})( jQuery );